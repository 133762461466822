import React, {useState, useEffect} from 'react';
import axios from 'axios';
import SideMenu from '../components/sideMenu';
import WeeklyCheckIn from '../components/weeklyCheckIn';
import SupplementsListWidget from '../components/supplementsWidget';
import BloodInsightsWidget from '../components/bloodWidget';
import GenomeInsightsWidget from '../components/genomeWidget';
import '../style/dashboard.css';

export default function DashBoard()
{
  const [userName, setUserName] = useState("");
  useEffect(() =>
  {
      let data;
      axios.get('http://localhost:8000/athlete/').then(res => {
          data = res.data;
          setUserName(data);
      }).catch(err => {
          console.log(err.response);
      })
  } ,[])

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-content">
        <h3>Welcome {userName}!</h3>
        <h1>Overview</h1>
        <div className="widget-content">
          <div className="left-content">
            <WeeklyCheckIn />
            <SupplementsListWidget />
          </div>
          <div className="right-content">
            <BloodInsightsWidget />
            <GenomeInsightsWidget />
          </div>
        </div>
      </div>
    </div>
  );
};
