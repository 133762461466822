import React from 'react';
import '../style/supplements.css';
import SideMenu from '../components/sideMenu';

export default function Supplements() {

    const supplements = [
        { icon: '🍊', name: 'Iron', dosage: '7.48 mg', sources: ['Blood Data'] },
        { icon: '🥬', name: 'Vitamin C', dosage: '87.77 mg', sources: ['Lifestyle', 'Blood Data'] },
        { icon: '🌞', name: 'Vitamin D', dosage: '14.29 mcg', sources: ['Blood Data'] },
        { icon: '🌾', name: 'Vitamin B12', dosage: '2.31 mcg', sources: ['Blood Data'] },
        { icon: '🔩', name: 'Calcium', dosage: '970 mg', sources: ['Lifestyle'] },
        { icon: '🥒', name: 'Zinc', dosage: '10.89 mg', sources: ['Lifestyle', 'Blood Data'] },
        { icon: '🥑', name: 'Magnesium', dosage: '286 mg', sources: ['Lifestyle', 'Blood Data'] },
        { icon: '🍯', name: 'Omega 3', dosage: '1.5 ml', sources: ['Blood Data'] },
      ];
        
    return (
        <div className="supplements-page">
            <SideMenu />
            <section className="main-display">
                <div className="display-header">
                    <h1 className="supplements">Your Supplements</h1>
                </div>
                <div className="supplement-body"> 
                    <div className="supplements-header">
                        <span>Daily Dosage</span>
                        <span>Source</span>
                    </div>
                    <ul className="supplements-list">
                            {supplements.map((supplement, index) => (
                        <li key={index} className="supplement-item">
                            {/* <span className="supplement-icon">{supplement.icon}</span> */}
                            <span className="supplement-name">{supplement.name}</span>
                            <span className="supplement-dosage">{supplement.dosage}</span>
                            <div className="supplement-source">
                                {supplement.sources.map((source, idx) => (
                                <span key={idx} className="source-tag">{source}</span>
                                ))}
                            </div>
                        </li>
                        ))}
                    </ul>
                </div>
            </section>
        </div>
    );
}