import React, { useState } from 'react';
import SideMenu from '../components/sideMenu';
import '../style/insights.css';

export default function Insights() {
    const [toggle, setToggle] = useState(1)
        
    function updateToggle(id) { 
        setToggle(id)          
    }

    return (
        <div className="insights-page">
            <SideMenu />
            <section className="main-display">
                <div className="display-header">
                    <h1 className="insights">Insights</h1>
                </div>
                <div className="tab-section"> 
                    <div className="tab-display">
                        <ul className="tabs">
                            <li className="tab-label" onClick={()=>updateToggle(1)}>Blood</li>
                            <li className="tab-label" onClick={()=>updateToggle(2)}>Genome</li>
                        </ul>
                    </div>
                    <div className="display-content">
                        <div className={toggle === 1 ? "show-content" : "tab-content"}>
                            <h2>Blood Result Summary</h2>
                            <p>Coming Soon</p>
                        </div>
                        <div className={toggle === 2 ? "show-content" : "tab-content"}>
                            <h2>Genome Result Summary</h2>
                            <p>Coming Soon</p>
                        </div>
                    </div>
                </div>
           </section>
        </div>

    );
}