import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './dashboard';
import logo from '../assets/logo-black.png';
import '../App.css';
import '../index.css';

export default function Login()
{

   const [userName, setUserName] = useState('');
   const [userIdCode, setUserIdCode] = useState('');

   const navigate = useNavigate();
   
   const onSubmit = (ev) =>
   {
       ev.preventDefault();
       const params = new URLSearchParams();
       params.append('userIdCode', userIdCode);
       params.append('userName', userName);

       axios({
          method: 'post',
          url: 'http://localhost:8000/athlete/',
          data: params
       }).then(res => {
           res.json("Logged in")
       }).catch((err) => {
           console.log(err)
       });
       navigate('/dashboard');
   };

   return (
    <form onSubmit={onSubmit} className={'mainContainer'}>
          <div className={'titleContainer'}>
            <div>OptiGenix</div>
            <img src={logo} className="logo-img-big" alt="logo-img"/>
          </div>
          <br />
          <div className={'inputContainer'}>
            <input
              value={userName}
              placeholder="Enter your name"
              onChange={(ev) => setUserName(ev.target.value)}
              className={'inputBox'}
            />
          </div>
          <br />
          <div className={'inputContainer'}>
            <input
              value={userIdCode}
              placeholder="Enter your user identification code"
               onChange={(ev) => setUserIdCode(ev.target.value)}
              className={'inputBox'}
            />
          </div>
          <br />
          <div className={'inputContainer'}>
            <button type="submit" className={'loginBtn'}>Login</button>
          </div>
      </form>
  );
};

