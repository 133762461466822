import React, { useState } from "react";
import { Link } from 'react-router-dom';
import '../style/widgets/bloodWidget.css';
import gauge from '../assets/gauge.png';

export default function BloodInsightsWidget(){
    const bloodResults = [
        {
            title: "Vitamin A",
            subtitle: "The Cancer Guardian Gene",
            description: "This gene helps regulate cell growth and can influence your likelihood of developing certain cancers.",
            img: gauge 
        },
        {
            title: "Vitamin B",
            subtitle: "Gene Guardian Cancer The",
            description: "This gene helps regulate cell growth and can influence your likelihood of developing certain cancers.",
            img: gauge
        },
        {
            title: "Vitamin C",
            subtitle: "Guardian Cancer Gene",
            description: "This gene helps regulate cell growth and can influence your likelihood of developing certain cancers.",
            img: gauge
        },  
    ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextInsight = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % bloodResults.length);
  };

  const prevInsight = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + bloodResults.length) % bloodResults.length);
  };

  return (
    <div className="blood-insights-container">
        <h2>Blood Insights</h2>
        <div className="blood-insight-card">
            <button className="card-button" onClick={prevInsight}>&lt;</button>
            <img src={bloodResults[currentIndex].img} className="gauge-img" alt="gauge-img"/>
            <div className="blood-insight-info">
                <h4>{bloodResults[currentIndex].title}</h4>
                {/* <h4>{bloodResults[currentIndex].subtitle}</h4> */}
                <p>{bloodResults[currentIndex].description}</p>
            </div>
            <button className="card-button" onClick={nextInsight}>&gt;</button>
        </div>
        <div className="full-insights">
            <Link to ='/insights' className="more-insights">&#43; Full Insights</Link>
        </div>
    </div>
  );
};
