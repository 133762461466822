import { React } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './views/login';
import Dashboard from './views/dashboard';
import Insights from './views/insights';
import Supplements from './views/supplements';
import './App.css';
import './index.css';

function App()
{

  return (
    <Router>
      <div className='pages'>
        <Routes>
          <Route path='/' element= {<Login/>}/>
          <Route path='/dashboard' element= {<Dashboard/>}/>
          <Route path='/supplements' element= {<Supplements/>}/>
          <Route path='/insights' element= {<Insights/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
