import React, { useState, useEffect } from 'react';
import '../style/widgets/weeklyCheckIn.css';

export default function WeeklyCheckIn() {
  const CHECK_IN_KEY = 'weeklyCheckIn';
  const ONE_WEEK = 0; //604800000; // week in ms

  const [checkInSubmitted, setCheckInSubmitted] = useState(false);

  useEffect(() => {
    const savedData = localStorage.getItem(CHECK_IN_KEY);
    if (savedData) {
      const { submitted, timestamp } = JSON.parse(savedData);
      const now = Date.now();

      if (submitted && now - timestamp < ONE_WEEK) {
        setCheckInSubmitted(true); 
      } else {
        localStorage.removeItem(CHECK_IN_KEY); 
      }
    }
  }, []);

  const handleCheckInSubmission = () => {
    const data = {
      submitted: true,
      timestamp: Date.now(),
    };
    localStorage.setItem(CHECK_IN_KEY, JSON.stringify(data));
    setCheckInSubmitted(true); 
  };

  return (
    <div className="weekly-check-in-container">
        {checkInSubmitted ? (
            <button className="check-in-button completed" disabled>
            <span className="icon">✅</span>
              Weekly Check-In Completed
            </button>
        ) : (
            <a
            href="https://forms.gle/1kUhWyPRTY5DWEsS7"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleCheckInSubmission}
            className="check-in-link"
            >
            <button className="check-in-button">
              <span className="icon">↗️</span>
                Weekly Check-In
            </button>
            </a>
        )}
    </div>
  );
}