import React from "react";
import "../style/widgets/genomeWidget.css";
import genome from '../assets/genome.png';

export default function GenomeInsightsWidget() {
    const genomeResults = [
        {
            title: "Coming Soon",
            description: "Discover the ways your genetic makeup influences your athletic performance and how it can help you reach new heights in your fitness goals.",
            img: genome,
        },
    ];

    return (
        <div className="genome-insights-container">
            <h2>Genome Insights</h2>
            <div className="genome-insight-card">
                <img src={genomeResults[0].img} className="genome-img" alt="genome-img"/>
                <div className="genome-insight-info">
                    <h4>{genomeResults[0].title}</h4>
                    <p>{genomeResults[0].description}</p>
                </div>
            </div>
        </div>
    );
};
