import React from "react";
import "../style/widgets/supplementsWidget.css";

export default function SupplementsListWidget() {
    const supplements = [
        {   
            icon: '🍊', 
            name: 'Iron', 
            dosage: '7.48 mg' 
        },
        { 
            icon: '🥬', 
            name: 'Vitamin C', 
            dosage: '87.77 mg'
        },
        { 
            icon: '🌞', 
            name: 'Vitamin D', 
            dosage: '14.29 mcg'
        },
        { 
            icon: '🌾', 
            name: 'Vitamin B12', 
            dosage: '2.31 mcg'
        },
        { 
            icon: '🔩', 
            name: 'Calcium', 
            dosage: '970 mg'
        },
        { 
            icon: '🥒', 
            name: 'Zinc', 
            dosage: '10.89 mg'
        },
        { 
            icon: '🥑', 
            name: 'Magnesium', 
            dosage: '286 mg'
        },
        { 
            icon: '🍯', 
            name: 'Omega 3', 
            dosage: '1.5 ml'
        },
    ];

  return (
    <div className="supplements-list-container">
        <div className="supplements-list-headers">
            <h2>My Supplements</h2>
        </div>
        <ul className="supplements-info">
            {supplements.map((supplement) => (
            <li key={supplement.name} className="supplement-item">
                {/* <span className="supplement-icon">{supplement.icon}</span> */}
                <span className="supplement-icon">{supplement.icon}</span>
                <span className="supplement-name">{supplement.name}</span>
                <span className="supplement-dosage">{supplement.dosage}</span>
                <div className="source">
                    <span>Lifestyle</span>
                    <span>Blood Data</span>
                </div>
            </li>
            ))}
        </ul>
    </div>
  );
};
