import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo-black.png';
import '../assets/user-black.png';
import '../style/sideMenu.css';


export default function SideMenu()
{ 
  const [click, setClick] = useState(false);
  const closeMenu = () => setClick(false);

  return (
      <div className="sidebar-container">
        <section className="sidemenu">
          <div className="logo">
            <img src={logo} className="logo-img" alt="logo-img"/>
            <Link to ='/dashboard' className={click ? "logo-text-active" : "logo-text"} onClick={closeMenu}>OptiGenix</Link>
          </div>
          <div className={click ? "side-menu active" : "side-menu"}>
            <ul>
              <li> 
                <Link to ='/dashboard' className={click ? "sidebar-text-active" : "sidebar-text"} onClick={closeMenu}>Overview</Link>
              </li>
              {/* <li> 
                <Link to ='/supplements' className={click ? "sidebar-text-active" : "sidebar-text"} onClick={closeMenu}>Supplements</Link>
              </li> */}
              <li> 
                <Link to ='/insights' className={click ? "sidebar-text-active" : "sidebar-text"} onClick={closeMenu}>Insights</Link>
              </li>
            </ul>
          </div>
          <div className="feedback">
            <Link to ='/' className={click ? "logo-text-active" : "logo-text"} onClick={closeMenu}>Logout</Link>
          </div>
        </section>
      </div>
    );
}
